
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopoverComponent } from '../popover/popover.component';
import { Router } from '@angular/router';
import { IUsuario } from 'src/app/models/usuario/usuario.model';
import { PaisesService } from 'src/app/services/paises/paises.service';
import { IPaisUser } from 'src/app/models/autenticacao/user.model';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  public user: IUsuario;

  paisSelecionado: IPaisUser;

  constructor(
    private popoverController: PopoverController,
    private authService: AuthService,
    private paisesServices: PaisesService,
    private router: Router
  ) {
   }

  ngOnInit() {
    this.user = this.authService.usuarioLogado;

    this.exibirMenu();
  }

  exibirMenu(){
    this.paisSelecionado = this.paisesServices.getPais();
  }

  async settingsPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }

  loggout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }


}
