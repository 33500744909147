/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { PopoverComponent } from './../popover/popover.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PopoverController, ToastController } from '@ionic/angular';
import { IPaisUser } from 'src/app/models/autenticacao/user.model';
import { ICategoriasMarca, IMarca } from 'src/app/models/marca/marca.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MarcaService } from 'src/app/services/marca/marca.service';
import { PaisesService } from 'src/app/services/paises/paises.service';
import { getErrorMessage } from 'src/app/utils/getErrorMessage';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-header-with-menus',
  templateUrl: './header-with-menus.component.html',
  styleUrls: ['./header-with-menus.component.scss'],
})
export class HeaderWithMenusComponent implements OnInit {

  public user;
  public id_marca;
  public titulo;
  public marcaTitulo;
  public id_categoria;

  urlImagemPublic =  `${environment.urlImagem}/public/files/marca`;

  marcasCat: ICategoriasMarca[] = [];
  id_segmento: string;

  marcas: IMarca[] = [];

  paisSelecionado: IPaisUser;

  imagemMarca: string[] = [];

  corOriginal = '#abaab0';

  rotaEspecificaAtiva;

  marcaAtiva: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private popoverController: PopoverController,
    private marcaService: MarcaService,
    private authService: AuthService,
    private paisesService: PaisesService,
    private toastController: ToastController,
  ) { }

  ngOnInit() {
    this.user = this.authService.usuarioLogado;
    this.id_marca = this.route.snapshot.paramMap.get('id');
    this.marcaTitulo = this.route.snapshot.paramMap.get('titulo');
    this.id_categoria = this.route.snapshot.paramMap.get('id_categoria');
    this.verificarRotaEspecifica();


    this.paisSelecionado = this.paisesService.getPais();

    this.buscarCategoriasPorMarca();

    this.marcaAtiva = this.marcaTitulo;

  }

  verificarRotaEspecifica() {
    this.rotaEspecificaAtiva = this.id_categoria !== null && this.id_categoria !== undefined;

    this.rotaEspecificaAtiva ? true : false;
  }

  buscarCategoriasPorMarca(){
    this.marcaService.getCategoriasPorMarca(this.id_marca)
    .subscribe({
      next: (response) => {
        this.marcasCat = response;

        this.marcasCat.forEach((marca)=> {
          this.id_segmento = marca.id_segmento;
        });
        this.listarMarcas();
      },
      error: (erro) => {
        getErrorMessage(erro, 'Erro ao listar marcas').forEach(
          msg => {
            this.onError(`Erro ao listar marcas - ${msg}`);
          },
        );
      }
    });
  }

  listarMarcas(){
    if(this.paisSelecionado){
      const idPais = this.paisSelecionado.id_pais;

      this.marcaService.getMarcaByIdPaisIdSeg(this.id_segmento, idPais)
      .subscribe({
        next: (response) => {
          this.marcas = response;

          this.exibirImagens();
        },
        error: (erro) => {
          getErrorMessage(erro, 'Erro ao listar marcas').forEach(
            msg => {
              this.onError(`Erro ao listar marcas - ${msg}`);
            }
          );
        }
      });
    }
  }

  exibirImagens(){
    this.marcas.forEach((marca, index)=> {
      const caminhoCompleto = marca.logo_miniatura;

      if(caminhoCompleto){
        const partesCaminho = caminhoCompleto.split('/');
        const nomeImagem = partesCaminho[partesCaminho.length - 1];

        const urlCompleta = `${this.urlImagemPublic}/${nomeImagem}`;
        this.imagemMarca[index] = urlCompleta;

      }
    });

  }

  async settingsPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }

  private async onError(message: string) {
    const toast = await this.toastController.create({
      message,
      buttons: [
        {
          text: 'OK',
          role: 'cancel'
        }
      ]
    });

    return await toast.present();
  }

}
