/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Valida se o erro recebido do backend é um erro tratado ou do servidor (quando esta offline ou erro interno)
 *
 * @param erro Objeto erro recebido quando uma request volta com status diferente de 200
 * @param texto_padrao Texto retornado caso o erro recebido do backend não tenha o objeto RetornoPadrao com uma mensagem já tratada.
 */
export function getErrorMessage(erro: any,texto_padrao: string): string[]{
  try {
    if (erro instanceof HttpErrorResponse) {
      if (erro.error instanceof ErrorEvent) {
        return [erro.error.message];
      }
    }
      //Retorno Node simples (RetornoPadrao)
      if(typeof erro.retorno !== 'undefined'){
          if(typeof erro.retorno.mensagem !== 'undefined'){
              return [erro.retorno.mensagem];
          }
      }

      if(Array.isArray(erro)) {
        if(typeof erro[0]?.retorno?.mensagem !== 'undefined'){
          const message: string[] = [];
          erro.forEach(err => {
            message.push(err.retorno.mensagem);
          });
          return message;
        }
      }

      //Se não passou em nenhuma das validações retorna texto_padrao
      return [texto_padrao];
  } catch (error) {
      return [texto_padrao];
  }
}
