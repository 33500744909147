import { RouterModule } from '@angular/router';
import { HeaderWithMenusComponent } from './header-with-menus/header-with-menus.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FooterComponent } from './footer/footer.component';
import { TitleComponent } from './title/title.component';
import { PopoverComponent } from './popover/popover.component';
import { NgModule, ModuleWithProviders, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { HeaderComponent } from './header/header.component';

import localept from '@angular/common/locales/pt';
import {registerLocaleData} from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SlickCarouselComponent } from './slick-carousel/slick-carousel.component';
registerLocaleData(localept, 'pt');


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    SlickCarouselModule
  ],
  declarations: [
    HeaderComponent,
    HeaderWithMenusComponent,
    PopoverComponent,
    TitleComponent,
    FooterComponent,
    SidebarComponent,
    SlickCarouselComponent
  ],
  exports: [
    CommonModule,
    IonicModule,
    HeaderWithMenusComponent,
    HeaderComponent,
    TitleComponent,
    FooterComponent,
    SidebarComponent,
    SlickCarouselComponent
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [ {provide: LOCALE_ID, useValue: 'pt'} ]
    };
  }
}
