/* eslint-disable @typescript-eslint/naming-convention */
import { catchError, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TokenService } from '../services/token/token.service';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private tokenService: TokenService,
    private auth: AuthService,
    private router: Router,
    private toastController: ToastController
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.auth.isUserAuthenticated()) {
      return next.handle(this.addToken(request, this.tokenService.getToken())).pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === 401) {
            return this.auth.refreshToken().pipe(
              switchMap((tokenResponse) => {
                this.auth.criarSessao(tokenResponse.token);
                const cloneReq = request.clone({
                  setHeaders: { Authorization: `${tokenResponse.token}` },
                });
                return next.handle(cloneReq);
              })
            );
            // this.toast.error('Sessão Expirada. Realize o login novamente');
            // this.matarSessao(errorResponse);
          } else if (errorResponse.status === 403) {
            this.matarSessao(errorResponse);
          }
          return throwError(() => errorResponse.error);
        })
      );
    }

    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === 401) {
        } else if (errorResponse.status === 403) {
          this.matarSessao(errorResponse);
        }
        return throwError(() => errorResponse.error);
      })
    );
  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: `${token}` } });
  }

  private async matarSessao({error}: any): Promise<void> {
    this.tokenService.removeToken();
    const toast = await this.toastController.create({
     message:  error.retorno.mensagem,
      buttons: [
        {
          text: 'OK',
          role: 'cancel'
        }
      ]
    });

    this.router.navigate(['login']);
    return await toast.present();
  }
}
