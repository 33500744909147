/* eslint-disable max-len */
import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, CanActivate } from '@angular/router';
import { NotAuthGuard } from './guards/not-auth-guard.service';


const routes: Routes = [
  {
    path: 'home/:id_pais',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [NotAuthGuard]
  },
  {
    path: 'esqueceu-senha',
    loadChildren: () => import('./pages/esqueceu-senha/esqueceu-senha.module').then( m => m.EsqueceuSenhaPageModule)
  },
  {
    path: 'trocar-senha',
    loadChildren: () => import('./pages/trocar-senha/trocar-senha.module').then( m => m.TrocarSenhaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'alterar-senha/:id',
    loadChildren: () => import('./pages/alterar-senha/alterar-senha.module').then( m => m.AlterarSenhaPageModule),
    canActivate: [NotAuthGuard]
  },
  {
    path: 'paises',
    loadChildren: () => import('./pages/paises/paises.module').then( m => m.PaisesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'recuperar-senha',
    loadChildren: () => import('./pages/recuperar-senha/recuperar-senha.module').then( m => m.RecuperarSenhaPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./pages/perfil/perfil.module').then( m => m.PerfilPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id',
    loadChildren: () => import('./pages/produtos/produtos.module').then( m => m.ProdutosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/marcas/:titulo',
    loadChildren: () => import('./pages/marcas/marcas.module').then( m => m.MarcasPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/marcas/:titulo/logomarca',
    loadChildren: () => import('./pages/logomarca/logomarca.module').then( m => m.LogomarcaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/marcas/:titulo/logomarca/:id_categoria_logomarca',
    loadChildren: () => import('./pages/lista-categorias-logomarcas/lista-categorias-logomarcas.module').then( m => m.ListaCategoriasLogomarcasPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/marcas/:titulo/logomarca/:id_categoria_logomarca/:id_logomarca',
    loadChildren: () => import('./pages/view-categorias/view-categorias.module').then( m => m.ViewCategoriasPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/marcas/:titulo/mix/:id_categoria',
    loadChildren: () => import('./pages/categoria-conteudo/categoria-conteudo.module').then( m => m.CategoriaConteudoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/marcas/:titulo/mix/:id_categoria/:id_conteudo',
    loadChildren: () => import('./pages/view-categoria-conteudo/view-categoria-conteudo.module').then( m => m.ViewCategoriaConteudoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/revenda/:titulo/:id_revenda_filial',
    loadChildren: () => import('./pages/view-revenda/view-revenda.module').then( m => m.ViewRevendaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/marcas/:marca/logomarca/pantone',
    loadChildren: () => import('./pages/lista-panetone/lista-panetone.module').then( m => m.ListaPanetonePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/marcas/:marca/logomarca/pantone/:idLogo',
    loadChildren: () => import('./pages/view-panetone/view-panetone.module').then( m => m.ViewPanetonePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/marcas/:marca/logomarca/monocromatica',
    loadChildren: () => import('./pages/lista-monocromatica/lista-monocromatica.module').then( m => m.ListaMonocromaticaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/marcas/:marca/logomarca/monocromatica/:idLogo',
    loadChildren: () => import('./pages/view-monocromatica/view-monocromatica.module').then( m => m.ViewMonocromaticaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/marcas/:marca/logomarca/traco',
    loadChildren: () => import('./pages/lista-traco/lista-traco.module').then( m => m.ListaTracoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/marcas/:marca/logomarca/traco/:idLogo',
    loadChildren: () => import('./pages/view-traco/view-traco.module').then( m => m.ViewTracoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/marcas/:marca/logomarca/incorreta',
    loadChildren: () => import('./pages/lista-apl-incorreta/lista-apl-incorreta.module').then( m => m.ListaAplIncorretaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/marcas/:marca/logomarca/incorreta/:idLogo',
    loadChildren: () => import('./pages/view-apl-incorreta/view-apl-incorreta.module').then( m => m.ViewAplIncorretaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/mix',
    loadChildren: () => import('./pages/mix/mix.module').then( m => m.MixPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'produtos/:id/mix/:nomeProduto',
    loadChildren: () => import('./pages/view-produto/view-produto.module').then( m => m.ViewProdutoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'novidades',
    loadChildren: () => import('./pages/novidades/novidades.module').then( m => m.NovidadesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'revenda-filiais',
    loadChildren: () => import('./pages/revenda-filiais/revenda-filiais.module').then( m => m.RevendaFiliaisPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'novidades/:novidade',
    loadChildren: () => import('./pages/view-novidade/view-novidade.module').then( m => m.ViewNovidadePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'nao-encontrado',
    loadChildren: () => import('./pages/nao-encontrado/nao-encontrado.module').then(m => m.NaoEncontradoPageModule),
    canActivate: [AuthGuard]
  },
  { path: '**',
    redirectTo: 'paises',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
