/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Service } from 'src/app/interfaces/service';
import { IPaisUser } from 'src/app/models/autenticacao/user.model';

@Injectable({
  providedIn: 'root'
})
export class PaisesService extends Service {

  private paisSelecionadoSource = new BehaviorSubject<any>(null);
  paisSelecionado$ = this.paisSelecionadoSource.asObservable();

  paisSelecionado: IPaisUser;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  atualizarPaisSelecionado(pais: IPaisUser) {
    // this.paisSelecionadoSource.next(pais);
    localStorage.setItem('paisSelecionado', JSON.stringify(pais));
  }

  setPais(pais: IPaisUser){
    this.paisSelecionado = pais;
  }

  getPais(){
    // return this.paisSelecionado;
    const storedPais = localStorage.getItem('paisSelecionado');
    return storedPais ? JSON.parse(storedPais) : null;
  }

  removerPais(){
    localStorage.removeItem('paisSelecionado');
    // this.paisSelecionado = undefined;
  }
}
