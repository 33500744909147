import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor(private jwtHelper: JwtHelperService) { }

  //The set method is use for encrypt the value.
  set(keys: any, value: any){
    const key = CryptoJS.enc.Utf8.parse(keys);
    const iv = CryptoJS.enc.Utf8.parse(keys);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    {
        keySize: 128 / 8,
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(keys: any, value: any){
    try {
      const key = CryptoJS.enc.Utf8.parse(keys);
      const iv = CryptoJS.enc.Utf8.parse(keys);
      const decrypted = CryptoJS.AES.decrypt(value, key, {
          keySize: 128 / 8,
          iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      throw error;
    }
  }

  decodeToken(token: any, keys: any): any {
    try {
      const tokenEnc = this.jwtHelper.decodeToken(token).tokenEnc;
      return JSON.parse(this.get(keys,tokenEnc));
    } catch (error) {
      return undefined;
    }
  }
}
