import { environment } from 'src/environments/environment';

export abstract class Service {
  protected readonly server: string;

  constructor() {
    this.server = environment.apiurl;
  }

  public getToken(): string {
    return JSON.parse(localStorage.getItem('@TOKEN'));
  }

  public getUserAuthenticated(): any {
    return JSON.parse(localStorage.getItem('@USER'));
  }
}
