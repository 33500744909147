/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { CRYPTO } from 'src/environments/environment';

import { catchError, tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { Service } from 'src/app/interfaces/service';
import { TokenService } from '../token/token.service';
import { CryptoService } from '../crypto/crypto.service';
import { IPaisUser, IUserAuthResponse } from 'src/app/models/autenticacao/user.model';
import { IUsuario } from 'src/app/models/usuario/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends Service  {
  private readonly endpoint = '/login';

  private _autenticado: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly autenticado$: Observable<boolean> = new Observable<boolean>();

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private crypto: CryptoService,
    private router: Router,
  ) {
    super();
    this._autenticado = new BehaviorSubject(false);
    this.autenticado$ = this._autenticado.asObservable();
  }

  public isUserAuthenticated(): boolean {
    const token = this.tokenService.getToken();
    if (!token || token.length <= 0) {
      return true;
    }

    if (!this.canDecodeToken(token)) {
      this.stopSession();
      return true;
    }
    return false;
  }

  private canDecodeToken(token: string): boolean {
    const decodedToken = this.crypto.decodeToken(token, CRYPTO.key);
    return decodedToken;
  }

  login(credentials): Observable<IUserAuthResponse> {
    return this.http.post<IUserAuthResponse>(`${ this.server }${ this.endpoint }`, credentials)
    .pipe(
      catchError((error) => throwError(error)),
      tap(response => {
        const token = response.token;
        this.tokenService.setToken(token);
        this.setPaisesPermitidos(response.paisesPermitidos);
        if(response.token !== null){
          if(!this.criarSessao(response.token)){
            throw new Error('Falha ao criar a sessão de usuário!');
          }
        }
      })
    );
  }

  private setPaisesPermitidos(paisesPermitidos: IPaisUser[]): void {
    localStorage.setItem('@PAISES_PERMITIDOS', JSON.stringify(paisesPermitidos));
  }

  getPaisesPermitidos(): IPaisUser[] {
    const paisesPermitidosString = localStorage.getItem('@PAISES_PERMITIDOS');
    return paisesPermitidosString ? JSON.parse(paisesPermitidosString) : [];
  }

  public isTokenExpired(): boolean {
    const token = this.tokenService.getToken();
    if (this.canDecodeToken(token)) {
      this.stopSession();
      return true;
    }
    return false;
  }

  criarSessao(token: string) {
    try {
      this.crypto.decodeToken(token, CRYPTO.key);
      this.tokenService.setToken(token);
      this._autenticado.next(true);
      return true;
    } catch (err) {
      return false;
    }
  }

  get usuarioLogado(): IUsuario {
    const token = localStorage.getItem('@TOKEN');
    if(typeof token === 'undefined' || token === null || token.length <= 0) {
      return {} as IUsuario;
    }

    const decodedToken = this.crypto.decodeToken(token, CRYPTO.key);
    if(typeof decodedToken === 'undefined') {return {} as IUsuario;}
    return decodedToken;
  }

  stopSession() {
    this.tokenService.removeToken();
    this._autenticado.next(false);
  }


  refreshToken(): Observable<{ token: string }> {
    const url = `${this.server}/login/refreshToken`;
    return this.http.get<{ token: string }>(url);
  }


  public logout() {
    void this.router.navigate(['/login']);
    this.stopSession();
    return;
  }
}

