import { Component } from '@angular/core';

@Component({
  selector: 'app-slick-carousel',
  templateUrl: './slick-carousel.component.html',
  styleUrls: ['./slick-carousel.component.scss'],
})
export class SlickCarouselComponent {
  slides = [
    { img: './assets/img/m1.jpg', link: '/home'},
    { img: './assets/img/m2.jpg', link: '/home' },
    { img: './assets/img/m3.jpg', link: '/produtos/1/marcas/BARKOV' },
    { img: './assets/img/m4.jpg', link: '/home'},
    { img: './assets/img/m5.jpg', link: '/home'},
    { img: './assets/img/m6.jpg', link: '/home'},
    { img: './assets/img/m7.jpg', link: '/home'},
    { img: './assets/img/m8.jpg', link: '/home'},
    { img: './assets/img/m9.jpg', link: '/home'},
    { img: './assets/img/m10.jpg', link: '/home'},
    { img: './assets/img/m11.jpg', link: '/home'},
    { img: './assets/img/m12.jpg', link: '/home'},
    { img: './assets/img/m13.jpg', link: '/produtos/2/marcas/Burguesa'},
    { img: './assets/img/m14.jpg', link: '/home'},
    { img: './assets/img/m15.jpg', link: '/home'},
    { img: './assets/img/m16.jpg', link: '/home'},
    { img: './assets/img/m17.jpg', link: '/home'},
    { img: './assets/img/m18.jpg', link: '/home'},
    { img: './assets/img/m19.jpg', link: '/produtos/3/marcas/BigPower'},
    { img: './assets/img/m20.jpg', link: '/home'},
  ];
  slideConfig = { slidesToShow: 10, slidesToScroll: 10 };
  addSlide() {
    this.slides.push({ img: 'http://placehold.it/350x150/777777', link: '/home' });
  }
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  slickInit(e: any) {
    console.log('slick initialized');
  }
  breakpoint(e: any) {
    console.log('breakpoint');
  }
  afterChange(e: any) {
    console.log('afterChange');
  }
  beforeChange(e: any) {
    console.log('beforeChange');
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor() {}

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit(): void {}
}
