/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Service } from 'src/app/interfaces/service';
import { ICategoriasMarca, IMarca } from 'src/app/models/marca/marca.model';

@Injectable({
  providedIn: 'root'
})
export class MarcaService extends Service {

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getMarcaByIdPaisIdSeg(id_segmento: string, id_pais: string): Observable<IMarca[]>{
    const url = `${this.server}/marca/${id_segmento}/${id_pais}`;

    return this.http.get<IMarca[]>(url);
  }

  getCategoriasPorMarca(id_marca: string): Observable<ICategoriasMarca[]>{
    const url = `${this.server}/marca/categoriaMarca/${id_marca}`;

    return this.http.get<ICategoriasMarca[]>(url);
  }

  getMarcaById(id_marca: string): Observable<IMarca> {
    const url = `${this.server}/marca/${id_marca}`;

    return this.http.get<IMarca>(url);
  }
}
