import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  logado = false;
  constructor(
    public auth: AuthService,
  ) {}

  ngOnInit() {
    this.auth.autenticado$.subscribe(auth => this.logado = auth);
  }
}
