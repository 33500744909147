/* eslint-disable @typescript-eslint/naming-convention */

import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PaisesService } from 'src/app/services/paises/paises.service';
import { IPaisUser } from 'src/app/models/autenticacao/user.model';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  public user: any;

  paisSelecionado: IPaisUser;

  label_trocar_senha: string;
  label_trocar_pais: string;
  label_meu_perfil: string;
  label_sair: string;

  constructor(
    private popoverController: PopoverController,
    private router: Router,
    private authService: AuthService,
    private paisesService: PaisesService
  ) { }

  ngOnInit() {
    this.user = this.authService.getUserAuthenticated();

    this.exibirTextoMenus();
  }

  exibirTextoMenus(){
    this.paisSelecionado = this.paisesService.getPais();

    if(this.paisSelecionado){
      this.label_trocar_senha = this.paisSelecionado.botao_trocar_senha;
      this.label_trocar_pais = this.paisSelecionado.botao_trocar_pais;
      // this.label_meu_perfil = this.paisSelecionado.botao_meu_perfil;
      this.label_sair = this.paisSelecionado.botao_sair;
    }
  }

  loggout() {
    this.authService.logout();
    this.close();
  }

  myProfile() {
    this.router.navigate(['/perfil']);
    this.close();
  }

  trocarSenha() {
    this.router.navigate(['/trocar-senha'], { skipLocationChange: false });
    this.close();
  }

  trocarPais() {
    this.paisesService.removerPais();
    this.router.navigate(['/paises']);
    this.close();
  }

  close(){
    this.popoverController.dismiss();
  }
}
