import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private jwtHelper: JwtHelperService,
  ) { }

  getToken() {
    return localStorage.getItem('@TOKEN') ?? '';
  }

  setToken(token: string) {
    localStorage.setItem('@TOKEN', token);
  }

  isExpiredToken(): boolean {
    return this.jwtHelper.isTokenExpired(this.getToken());
  }

  removeToken(): void {
    localStorage.removeItem('@TOKEN');
  }
}
