/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { IPaisUser } from 'src/app/models/autenticacao/user.model';
import { PaisesService } from 'src/app/services/paises/paises.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  paisSelecionado: IPaisUser;

  texto_rodape: string;

  constructor(
    private paisesService: PaisesService,
  ) { }

  ngOnInit() {
    this.exibirTextoRodape();
  }

  exibirTextoRodape(){
    this.paisSelecionado = this.paisesService.getPais();

    this.texto_rodape = this.paisSelecionado ? this.paisSelecionado.texto_rodape : '';

  }

}
