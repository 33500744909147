import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

 public isRemainder;
 public isRemainder1;
 public isRemainder2;
 public isRemainder3;

  constructor(
    private modalCtrl: ModalController
    ) { }

  ngOnInit() {}

  // public async presentModal() {
  //   const modal = await this.modalCtrl.create({
  //     component: AddUserComponent,
  //     cssClass: 'large-modal'
  //   });

  //   return await modal.present();
  // }

}
